// React
import React from 'react'

// Common components
import withSplashScreen from './components/Common/withSplashScreen'
import Aside from './components/Layouts/Aside'
import Home from './pages/Home'
import Campaign from './components/Common/Campaign'

// Router
import { Router, Switch, Route } from 'react-router-dom'
import { createBrowserHistory } from 'history'

// Styles
import './styles/_bootstrap.scss'
import './styles/global.css'
import './styles/products.css'

// Stats
import ReactGA from 'react-ga'
import MuiTheme from './components/Layouts/MuiTheme'
// import ReactPixel from 'react-facebook-pixel'
// import TagManager from 'react-gtm-module'

// Common components
const Snackbar = React.lazy(() => import('./components/Layouts/Snackbar'))
const ErrorBoundary = React.lazy(() => import('./components/Layouts/ErrorBoundary'))
const PrivateRoute = React.lazy(() => import('./components/Common/PrivateRoute'))
const Loader = React.lazy(() => import('./components/Layouts/Loader'))

// Pages
const NotFound = React.lazy(() => import('./pages/NotFound'))
const LogIn = React.lazy(() => import(/* webpackMode: 'eager' */'./pages/LogIn'))
const SignUp = React.lazy(() => import(/* webpackMode: 'eager' */'./pages/SignUp'))
const Search = React.lazy(() => import('./pages/Search'))
const Branch = React.lazy(() => import('./pages/Branch'))
const Product = React.lazy(() => import('./pages/Product'))
const EditProduct = React.lazy(() => import('./pages/EditProduct'))
const Cart = React.lazy(() => import('./pages/Cart'))
const Checkout = React.lazy(() => import('./pages/Checkout'))
const ActiveOrderNow = React.lazy(() => import('./pages/ActiveOrderNow'))
const Info = React.lazy(() => import('./pages/Info'))
const OrderStatus = React.lazy(() => import('./pages/OrderStatus'))
const MyOrders = React.lazy(() => import('./pages/MyOrders'))
const Order = React.lazy(() => import('./pages/Order'))
const MyAddresses = React.lazy(() => import('./pages/MyAddresses'))
const NewAddress = React.lazy(() => import('./components/Addresses/New'))
const EditAddress = React.lazy(() => import('./components/Addresses/Edit'))
const Feedback = React.lazy(() => import('./pages/Feedback'))
const Reservation = React.lazy(() => import('./pages/Reservation'))
const MyReservations = React.lazy(() => import('./pages/MyReservations'))
const ReservationStatus = React.lazy(() => import('./pages/ReservationStatus'))
const Comment = React.lazy(() => import('./pages/Comment'))

const routes = [
  { path: '/', name: 'Home', Component: Home, exact: true },
  { path: '/branch', name: 'Branch', Component: Branch, exact: true },
  { path: '/login', name: 'Log In', Component: LogIn, exact: true },
  { path: '/sign_up', name: 'Sign Up', Component: SignUp, exact: true },
  { path: '/search', name: 'Search', Component: Search, exact: true },
  { path: '/product/:id', name: 'Product', Component: Product, exact: true },
  { path: '/product/:id/*', name: 'Product', Component: Product, exact: true },
  { path: '/edit_product/:id', name: 'EditProduct', Component: EditProduct, exact: true },
  { path: '/cart', name: 'Cart', Component: Cart, exact: true },
  { path: '/info', name: 'Info', Component: Info, exact: true },
  { path: '/feedback', name: 'Feedback', Component: Feedback, exact: true },
  { path: '/comment', name: 'Comment', Component: Comment, exact: true },
  { path: '/checkout', name: 'Checkout', Component: Checkout, exact: true },
  { path: '/reservation', name: 'Reservation', Component: Reservation, exact: true }
]

const privateRoutes = [
  { path: '/active_order_now', name: 'Active Order Now', Component: ActiveOrderNow, exact: true },
  { path: '/order/:id', name: 'Order', Component: Order, exact: true },
  { path: '/order_status/:id', name: 'OrderStatus', Component: OrderStatus, exact: true },
  { path: '/my_orders', name: 'MyOrders', Component: MyOrders, exact: true },
  { path: '/my_addresses', name: 'MyAddresses', Component: MyAddresses, exact: true },
  { path: '/address/new', name: 'NewAddress', Component: NewAddress, exact: true },
  { path: '/address/edit/:id', name: 'EditAddress', Component: EditAddress, exact: true },
  { path: '/my_reservations', name: 'MyReservations', Component: MyReservations, exact: true },
  { path: '/reservation_status/:id', name: 'ReservationStatus', Component: ReservationStatus, exact: true }
]

/* Google Analytics */
ReactGA.initialize('UA-128259329-2')

/* Google Tag Manager */
// const tagManagerArgs = {
//   gtmId: 'GTM-NNMZ725'
// }
// TagManager.initialize(tagManagerArgs)

/* Facebook Pixel */
// const options = {
//     autoConfig: true,
//     debug: false,
// }
// ReactPixel.init('730610234049165', {}, options)

const history = createBrowserHistory()
history.listen(location => {
  ReactGA.pageview(location.pathname)
  // ReactPixel.pageView()
  // TagManager.dataLayer(tagManagerArgs)
})

function App(props) {
  return (
    <Router history={history}>
      <Aside />
      <React.Suspense fallback={null}>
        <MuiTheme>
          <ErrorBoundary>
            <Loader />
            <Switch>
              {routes.map(({ path, exact, Component }) => (
                <Route key={path} path={path} component={Component} exact={exact} />
              ))}

              {privateRoutes.map(({ path, exact, Component }) => (
                <PrivateRoute key={path} path={path} component={Component} exact={exact} />
              ))}

              <Route component={NotFound} />
            </Switch>
            <Campaign />
            <Snackbar />
          </ErrorBoundary>
        </MuiTheme>
      </React.Suspense>
    </Router>
  )
}

export default withSplashScreen(App)
